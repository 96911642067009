  <template>
    <v-row class="pb-8">
      <v-col 
        cols="12" 
        sm="12" 
        class="pt-0"
      >  
      <v-responsive max-height="300px" class="d-flex justify-center align-center">
  <img 
    src="/img/hospital_cassems.jpg" 
    alt="Hospital Cassems"
    class="w-100" 
    style="object-fit: contain;"
  />
</v-responsive>
        <v-row>
          <v-col 
            xs="12" 
            sm="12" 
            md="12" 
            align-self="center"
          >
            <session-title
              class="pt-8 px-8 pb-0"
              title="Sugestões ou Reclamações"
              description="Esse espaço é destinado a divulgação de boas práticas, ideias e sugestões de melhoria.
          Compartilhe conosco projetos, ações e momentos especiais que viveu em seu dia a dia na CASSEMS.
          Caso tenha reclamações a registrar sobre suas preocupações profissionais a respeito do ambiente da empresa, esse também é o canal certo e você também pode registrar de forma anônima. Compartilhe!"
            />
          </v-col>
          <v-col 
            class="pt-0 px-11 pb-3" 
            align-self="center"
          >
            <chat-ti-modal-access-vue />
          </v-col>
        </v-row>
      </v-col>

      <v-form 
        ref="form" 
        class="pt-8 px-8 pb-0"
      >
        <v-row 
          justify="start" 
          no-gutters 
          class="pa-2 mt-0"
        >
          <v-col 
            class="d-flex pr-5" 
            lg="4" 
            md="4" 
            sm="4"
          >
            <v-select
              v-model="suggestion.type"
              :items="items"
              label="Tipo *"
              outlined
              return-object
              :rules="$rules.required"
              required
            />
          </v-col>
          <v-col 
            class="d-flex pr-5" 
            lg="4" 
            md="4" 
            sm="4"
          >
            <v-select
              v-model="suggestion.city"
              :items="cities"
              label="Cidade *"
              outlined
              return-object
              :rules="$rules.required"
              required
            />
          </v-col>
          <v-col 
            class="d-flex pr-5" 
            lg="4" 
            md="4" 
            sm="4"
          >
            <v-select
              v-if="suggestion.type == 'Reclamação'"
              v-model="suggestion.category"
              :items="typeComplaint"
              label="Categoria *"
              outlined
              return-object
              :rules="$rules.required"
              required
            />
            <v-select
              v-if="suggestion.type == 'Sugestão'"
              v-model="suggestion.category"
              :items="typeSuggestion"
              label="Categoria *"
              outlined
              return-object
              :rules="$rules.required"
              required
            />
          </v-col>
          <v-alert 
            border="left" 
            color="blue" 
            dense 
            text 
            type="info"
          >
            Ao escrever sua mensagem, descreva detalhadamente com nomes, setor e
            cidade. Isso facilitará a tratativa da sua sugestão ou
            reclamação.
          </v-alert>
          <h4 />
          <v-container 
            class="px-0" 
            fluid
          >
            <v-checkbox 
              v-model="nameless" 
              value="nameless" 
              label="Anônimo" 
            />
          </v-container>

          <v-col 
            class="pr-5" 
            lg="12" 
            md="12" 
            sm="12"
          >
            <v-textarea
              v-model="suggestion.text"
              label="Texto *"
              :rules="$rules.required"
              outlined
              required
            />
          </v-col>

          <v-col>
            <v-btn 
              class="mr-2" 
              color="primary" 
              @click="clearForm"
            >
              Cancelar
            </v-btn>
            <v-btn
              v-if="loading === true"
              color="secondary"
              loading
              @click="submit"
            />
            <v-btn 
              v-else 
              color="secondary" 
              @click="submit"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-row>
  </template>

  <script>
  import SessionTitle from "@/components/SessionTitle";
  import Suggestion from "../../services/suggestion-service";
  import ChatTiModalAccessVue from "../../components/ModalViews/ChatTiModalAccess.vue";

  export default {
    name: "SuggestionForm",
    components: {
      SessionTitle,
      ChatTiModalAccessVue
    },
    data() {
      return {
        suggestion: {
          type: null,
          text: null,
          user: "Anônimo",
          city: null,
          category: null,
        },
        loading: false,
        items: ["Sugestão", "Reclamação"],
        cities:["Campo Grande","Dourados","Três Lagoas","Corumbá","Ponta Porã","Sidrolândia","Naviraí","Nova Andradina","Maracaju","Aquidauana","Paranaíba","Amambai",
        "Rio Brilhante","Coxim","Caarapó","Miranda","São Gabriel do Oeste","Chapadão do Sul","Aparecida do Taboado","Jardim","Itaporã","Anastácio","Ribas do Rio Pardo",
        "Bela Vista","Ladário","Bataguassu","Ivinhema","Nova Alvorada do Sul","Terenos"," Bonito","Cassilândia","Itaquiraí","Costa Rica","Sonora","Rio Verde de Mato Grosso",
        "Fátima do Sul","Mundo Novo","Porto Murtinho","Iguatemi","Água Clara","Coronel Sapucaia","Paranhos","Nioaque","Camapuã","Deodápolis","Aral Moreira","Eldorado","Brasilândia",
        "Tacuru","Dois Irmãos do Buriti","Batayporã","Angélica","Sete Quedas","Glória de Dourados","Guia Lopes da Laguna","Japorã","Anaurilândia","Antônio João","Santa Rita do Pardo",
        "Bodoquena","Pedro Gomes","Inocência","Laguna Carapã","Jaraguari","Bandeirantes","Juti","Selvíria","Caracol","Corguinho","Vicentina","Douradina","Paraíso das Águas","Alcinópolis",
        "Rochedo","Rio Negro","Jateí","Taquarussu","Novo Horizonte do Sul","Figueirão"],
        typeComplaint: [ "Processos Internos", "Fluxos de atividades", "Preocupações","Sistemas","Infraestrutura","Outros"],
        typeSuggestion: [ "Otimizar Resultados", "Melhorias para empresa", "Sugestões de Treinamentos e ações", "Sistemas", "Outros"],
        nameless: false
      };
    },
    methods: {
      async submit() {
        if (!this.suggestion.text || !this.suggestion.type || !this.suggestion.city || !this.suggestion.category) {
          this.$toast.error("Verifique os campos e tente novamente");
          return;
        }

        try {
          this.loading = true;
          !this.nameless ? this.suggestion.user = window.localStorage.getItem("cassems_user") : this.suggestion.user = 'Anônimo';
          await Suggestion.postSuggestionExternal({ ...this.suggestion });
          this.clearForm();
          this.$toast.success("Sugestão/Reclamação cadastrada com sucesso");
          this.loading = false;
        } catch (e) {
          this.$handleHttpError(e);
          this.loading = false;
        }
      },
      clearForm() {
        this.$refs.form.reset();
        this.suggestion.text = null;
        this.$refs.form.resetValidation();
      }
    }
  };
  </script>
